import { styled } from "styled-components";

const ProductItem = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease-in-out;

  .description {
    display: flex;
    font-size: 14px;
    color: #666;
    height: 40px;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* 라인수 */
    -webkit-box-orient: vertical;
  }

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
  }

  @media (max-width: 768px) {
    .description {
      display: none;
    }
    h3 {
      font-size: 16px;
    }
  }
`;

const ProductPrice = styled.div`
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: 500;
  color: #ff9eaa;

  button {
    background-color: #ff9eaa;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }

  button:hover {
    background-color: #fff;
    color: #ff9eaa;
    border: 1px solid #ff9eaa;
  }
`;

export function ProductItemComponent({
  service,
  setServiceItem,
  setModalShow,
}) {
  const onClick = () => {
    setServiceItem(service);
    setModalShow(true);
  };
  return (
    <ProductItem onClick={onClick}>
      <img src={service.image} alt="" />
      <div>
        <h3>{service.name}</h3>
        <p className="description">{service.description}</p>
      </div>
      <ProductPrice>
        <p>1회 {service.price}원</p>
        <button>구매하기</button>
      </ProductPrice>
    </ProductItem>
  );
}
